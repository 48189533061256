<clr-modal
	[clrModalOpen]="open$ | async"
	(clrModalOpenChange)="configuration.onCancel()"
	[clrModalSize]="'m'"
	*ngIf="config$ | async as configuration">
	<div class="modal-title" [innerHTML]="configuration.title"></div>
	<div class="modal-body" [innerHTML]="configuration.body"></div>
	<div class="modal-footer">
		<button class="btn btn-outline" type="button" (click)="configuration.onCancel()">Cancel</button>
		<button class="btn btn-primary" type="button" (click)="configuration.onAccept()">Ok</button>
	</div>
</clr-modal>
