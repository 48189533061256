<div class="modal">
	<div class="modal-dialog">
		<div class="row">
			<div class="card">
				<div>
					<div class="card-block">
						<div class="card-text">
							<div>
								<h3>Something's went wrong here...</h3>
								<br />
								<span>2 possibilities :</span>
								<ul>
									<li>The page doesn't exist</li>
									<li>You don't have the right access to view it ! (Nice try dude...)</li>
								</ul>
								<br />

								<div style="width: 100%; text-align: center">
									<img src="../../assets/images/hacker.png" />
								</div>

								<br />
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div>
							<a routerLink="/">
								<button class="btn btn-warning">
									<cds-icon shape="home"></cds-icon>
									Take Me Home
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
