import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
	confirmationModalState$ = new BehaviorSubject<{
		open: boolean;
		configuration: ConfirmationModalConfiguration;
	}>(reset);

	open(confirmationModalConfiguration: ConfirmationModalConfiguration) {
		this.confirmationModalState$.next({
			open: true,
			configuration: confirmationModalConfiguration
		});
	}

	close() {
		this.confirmationModalState$.next(reset);
	}
}

const reset = { open: false, configuration: {} as any };
export interface ConfirmationModalConfiguration {
	title: string;
	body: string;
	onAccept: () => void;
	onCancel: () => void;
}
