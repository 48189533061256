import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClrIconModule } from '@clr/angular';
import { AlertMessageComponent } from './alert/alert-message.component';
import { ConfirmationModalModule } from './confirmation-modal/confirmation-modal.module';
import { ThrottleClickDirective } from './directive/ThrottleClickDirective.directive';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { JwtInterceptor } from '../helpers/jwt.interceptor';

@NgModule({
	declarations: [SafeUrlPipe, AlertMessageComponent, PdfViewerComponent, ThrottleClickDirective],
	exports: [SafeUrlPipe, AlertMessageComponent, PdfViewerComponent, ThrottleClickDirective, ConfirmationModalModule],
	imports: [CommonModule, ClrIconModule, ConfirmationModalModule],
	providers: [JwtInterceptor]
})
export class SharedModule {}
