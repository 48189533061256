<clr-main-container>
	<clr-header class="header-total">
		<div class="branding">
			<div class="title" style="flex: 1">
				<a routerLink="/">
					<span class="header-title">PRODUCT</span>
					<span style="color: var(--total-red); font-family: 'Netto Offc W02 Bold'">catalog</span>
					<span style="color: #c6c8c8; font-size: 11px; font-style: italic">provided by</span>
					<img alt="" src="assets/images/logo_te.svg" />
				</a>
			</div>
		</div>

		<div class="settings">
			<clr-dropdown>
				<button class="dropdown-toggle btn btn-link" clrDropdownTrigger style="color: var(--total-red)">
					<cds-icon shape="user"></cds-icon>
					{{ userDetails?.lastName }} {{ userDetails?.firstName }}
					<cds-icon shape="caret" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrposition="bottom-right">
					<div class="dropdown-item">
						<cds-icon style="color: dimgrey" shape="crown" class="is-solid"></cds-icon>
						{{ userDetails?.role | titlecase }}
					</div>
					<a clrDropdownItem (click)="doLogout()">
						<cds-icon style="color: dimgrey" shape="logout"></cds-icon>
						Log out
					</a>

					<a
						*ngIf="userService.hasAdminRole()"
						clrDropdownItem
						routerLink="caches"
						routerLinkActive="active"
						class="caches-nav">
						<cds-icon style="color: dimgrey" shape="eraser"></cds-icon>
						Clear Caches
					</a>
					<a clrDropdownItem (click)="getJWTToken()">
						<cds-icon style="color: dimgrey" shape="clipboard"></cds-icon>
						Copy token
					</a>
					<a clrDropdownItem (click)="opened = true">
						<cds-icon style="color: dimgrey" shape="info-circle"></cds-icon>
						About
					</a>
				</clr-dropdown-menu>
			</clr-dropdown>
		</div>
	</clr-header>

	<div class="content-container">
		<div #title class="content-area">
			<router-outlet></router-outlet>
		</div>
		<clr-vertical-nav
			class="nav-trigger--bottom"
			[clrVerticalNavCollapsible]="collapsible"
			[(clrVerticalNavCollapsed)]="collapse">
			<a clrVerticalNavLink routerLink="bundles" routerLinkActive="active" class="bundles-nav">
				<cds-icon clrVerticalNavIcon shape="shopping-cart" class="bundles-nav is-solid"></cds-icon>
				Bundles
			</a>
			<a clrVerticalNavLink routerLink="products" routerLinkActive="active" class="products-nav">
				<cds-icon clrVerticalNavIcon shape="star" class="products-nav"></cds-icon>
				Products
			</a>
			<clr-vertical-nav-group>
				<cds-icon clrVerticalNavIcon shape="savings" class="promotions-nav"></cds-icon>
				Promotions
				<clr-vertical-nav-group-children>
					<a class="nav-link geeps-nav" routerLink="promotions/list/standards" routerLinkActive="active">Standards</a>
					<a class="nav-link geeps-nav" routerLink="promotions/list/pixel_partners" routerLinkActive="active">
						Pixel Partners
					</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<a clrVerticalNavLink routerLink="sales-context" routerLinkActive="active" class="sales-context-nav">
				<cds-icon clrVerticalNavIcon shape="dollar" class="sales-context-nav"></cds-icon>
				Sales Context
			</a>
			<clr-vertical-nav-group *ngIf="userService.hasTariffCardAdminRole() || userService.hasAdminRole()">
				<cds-icon clrVerticalNavIcon shape="details" class="promotions-nav"></cds-icon>
				Tariff card
				<clr-vertical-nav-group-children>
					<a class="nav-link geeps-nav" routerLink="tariff-card/visualization" routerLinkActive="active">Visualization</a>
					<a class="nav-link geeps-nav" routerLink="tariff-card/validation" routerLinkActive="active">Validation</a>
					<a class="nav-link geeps-nav" routerLink="tariff-card/template" routerLinkActive="active">Template</a>
					<a class="nav-link geeps-nav" routerLink="tariff-card/monitor" routerLinkActive="active">Monitoring</a>
					<a class="nav-link geeps-nav" routerLink="tariff-card/manual-adding" routerLinkActive="active">Manual adding</a>
				</clr-vertical-nav-group-children>
			</clr-vertical-nav-group>
			<div class="nav-divider"></div>
			<a
				*ngIf="userService.hasAdminRole()"
				clrVerticalNavLink
				routerLink="monitoring"
				routerLinkActive="active"
				class="monitoring-nav">
				<cds-icon clrVerticalNavIcon shape="bug" class="monitoring-nav"></cds-icon>
				Monitoring
			</a>
			<a
				*ngIf="userService.hasAdminRole()"
				clrVerticalNavLink
				routerLink="export"
				routerLinkActive="active"
				class="export-nav">
				<cds-icon clrVerticalNavIcon shape="export" class="export-nav"></cds-icon>
				Export
			</a>
			<a
				*ngIf="userService.hasAdminRole()"
				clrVerticalNavLink
				routerLink="import"
				routerLinkActive="active"
				class="import-nav">
				<cds-icon clrVerticalNavIcon shape="import" class="import-nav"></cds-icon>
				Import
			</a>
			<div *ngIf="!collapse && userService.hasAdminRole()">
				<div class="pipeline_data">
					<p>
						F :
						<a
							[href]="
								'https://gitlab.lampiris.be/community/be-product-catalog-front/-/pipelines/' + pipelineData.frontend.pipeline_id
							">
							{{ pipelineData.frontend.pipeline_id }}
						</a>
						<span>- {{ pipelineData.frontend.pipeline_timestamp | date: 'dd/MM HH:mm' }}</span>
					</p>
					<p>
						B :
						<a
							[href]="
								'https://gitlab.lampiris.be/community/be-product-catalog/-/pipelines/' + pipelineData.backend.pipeline_id
							">
							{{ pipelineData.backend.pipeline_id }}
						</a>
						<span>- {{ pipelineData.backend.pipeline_timestamp | date: 'dd/MM HH:mm' }}</span>
					</p>
				</div>
			</div>
		</clr-vertical-nav>
	</div>
</clr-main-container>

<div *ngIf="opened">
	<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="false">
		<h3 class="modal-title">About</h3>
		<div class="modal-body">
			<p>Product Catalog - Version {{ appVersion }} - All right reserved to Total Energies.</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-primary" (click)="opened = false; title.id = 'admin-title'">
				<cds-icon shape="bug" solid="true"></cds-icon>
				OK
			</button>
			<button type="button" class="btn btn-primary" (click)="opened = false">OK</button>
		</div>
	</clr-modal>
</div>

<app-confirmation-modal></app-confirmation-modal>
