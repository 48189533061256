import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/fr-BE';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './config/app-config';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NotFoundModule } from './not-found/not-found.module';
import { BootstrapComponent } from './root.component';
import { AuthCognitoService } from './services/auth-cognito.service';
import { UserService } from './services/user.service';
import { SharedModule } from './shared/shared.module';
import { initializer } from './utils/app-init';
import { CdsIconModule } from '@cds/angular';

registerLocaleData(locale);

@NgModule({
	declarations: [BootstrapComponent, AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ClarityModule,
		NotFoundModule,
		SharedModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			timeOut: 4000,
			positionClass: 'toast-bottom-right'
		}),
		CdsIconModule
	],
	providers: [
		JwtInterceptor,
		{ provide: LOCALE_ID, useValue: 'fr-BE' },
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [UserService, AppConfig, AuthCognitoService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	bootstrap: [BootstrapComponent]
})
export class AppModule {}
