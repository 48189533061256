import { inject, Injectable } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';

@Injectable({
	providedIn: 'root'
})
class AclGuardService {
	constructor(private userService: UserService) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.userService.hasAdminRole() || this.userService.hasUserRole() || this.userService.hasTariffCardAdminRole();
	}
}

export const AclGuard: CanActivateFn = () => {
	return inject(AclGuardService).canActivate();
};
