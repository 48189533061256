export interface User {
	id?: string;
	username?: string;
	accountName?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	createdTimestamp?: number;
	role: Roles;
}

export enum Roles {
	BUSINESS_ADMIN = 'business_admin',
	ADMIN = 'admin',
	USER = 'user',
	TARIFF_CARD_ADMIN = 'tariff-card-admin'
}
