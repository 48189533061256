import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationModalConfiguration, ConfirmationModalService } from './confirmation-modal.service';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
	open$: Observable<boolean>;
	config$: Observable<ConfirmationModalConfiguration>;

	constructor(public confirmationModalService: ConfirmationModalService) {
		this.config$ = confirmationModalService.confirmationModalState$.pipe(map((config) => config.configuration));
		this.open$ = confirmationModalService.confirmationModalState$.pipe(map((config) => config.open));
	}
}
