import { AppConfig } from '../config/app-config';
import { AuthCognitoService } from '../services/auth-cognito.service';
import { UserService } from '../services/user.service';

export const initializer =
	(userService: UserService, config: AppConfig, cognitoService: AuthCognitoService): (() => Promise<any>) =>
	(): Promise<any> =>
		// eslint-disable-next-line no-async-promise-executor
		new Promise(async (resolve, reject) => {
			try {
				await config.load();
				const isAuthenticated = await cognitoService.isAuthenticated();
				if (!isAuthenticated) {
					cognitoService.federatedSignIn();
				}
				const userDetails = await cognitoService.getUser();
				userService.setUserFromCognito(userDetails);
				await config.loadPipelineData();
				resolve(true);
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
