import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AclGuard } from './shared/guards/acl.guard';

// routes
const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'app/bundles',
		pathMatch: 'full'
	},
	{
		path: 'products',
		redirectTo: 'app/products',
		pathMatch: 'full'
	},
	{
		path: 'billing-models',
		redirectTo: 'app/billing-models',
		pathMatch: 'full'
	},
	{
		path: 'export',
		redirectTo: 'app/export',
		pathMatch: 'full'
	},
	{
		path: 'import',
		redirectTo: 'app/import',
		pathMatch: 'full'
	},
	{
		path: 'bundles',
		redirectTo: 'app/bundles',
		pathMatch: 'full'
	},
	{
		path: 'promotions',
		redirectTo: 'app/promotions',
		pathMatch: 'full'
	},
	{
		path: 'monitoring',
		redirectTo: 'app/monitoring',
		pathMatch: 'full'
	},
	{
		path: 'tariff-card',
		redirectTo: 'app/tariff-card',
		pathMatch: 'full'
	},
	{
		path: 'caches',
		redirectTo: 'app/caches',
		pathMatch: 'full'
	},
	{
		path: 'app',
		component: AppComponent,
		children: [
			{
				path: 'products',
				loadChildren: () => import('./model/products/products.module').then((m) => m.ProductsModule)
			},
			{
				path: 'bundles',
				loadChildren: () => import('./model/bundles/bundles.module').then((m) => m.BundlesModule)
			},
			{
				path: 'promotions',
				loadChildren: () => import('./model/promotions/promotions.module').then((m) => m.PromotionsModule)
			},
			{
				path: 'sales-context',
				loadChildren: () => import('./model/sales-context/sales-context.module').then((m) => m.SalesContextModule)
			},
			{
				path: 'export',
				loadChildren: () => import('./model/export/export.module').then((m) => m.ExportModule)
			},
			{
				path: 'import',
				loadChildren: () => import('./model/import/import.module').then((m) => m.ImportModule)
			},
			{
				path: 'monitoring',
				loadChildren: () =>
					import('./model/synchronization-monitoring/synchronization-monitoring.module').then(
						(m) => m.SynchronizationMonitoringModule
					)
			},
			{
				path: 'billing-models',
				loadChildren: () => import('./model/billing-models/billing-models.module').then((m) => m.BillingModelsModule)
			},
			{
				path: 'tariff-card',
				loadChildren: () => import('./model/tariff-card/tariff-card.module').then((m) => m.TariffCardModule),
				data: {
					roles: ['be-product-catalog-tariff-card-admin', 'be-product-catalog-admin']
				},
				canActivate: [AclGuard]
			},
			{
				path: 'caches',
				loadChildren: () => import('./model/caches/caches.module').then((m) => m.CachesModule)
			}
		]
	},
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
