import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { NotFoundComponent } from './not-found.component';

@NgModule({
	declarations: [NotFoundComponent],
	exports: [NotFoundComponent],
	imports: [CommonModule, ClarityModule, FormsModule, HttpClientModule, RouterModule]
})
export class NotFoundModule {}
