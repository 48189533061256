import { Component, OnInit } from '@angular/core';
import * as cds from '@cds/core/icon';
import '@cds/core/icon/register.js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ToastrService } from 'ngx-toastr';
import packageInfo from '../../package.json';
import { AppConfig } from './config/app-config';
import { PipelinesData } from './interfaces/pipeline_data';
import { User } from './interfaces/user';
import { AuthCognitoService } from './services/auth-cognito.service';
import { UserService } from './services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	title = 'be-product-catalog-front';
	userDetails: User;
	collapsible = true;
	collapse = false;
	opened = false;
	public appVersion;

	constructor(
		private toastr: ToastrService,
		public userService: UserService,
		private authCognitoService: AuthCognitoService,
		private appConfig: AppConfig
	) {
		cds.loadCoreIconSet();
		cds.loadEssentialIconSet();
		cds.loadCommerceIconSet();
		cds.loadMediaIconSet();
		cds.loadSocialIconSet();
		cds.loadTravelIconSet();
		cds.loadTextEditIconSet();
		cds.loadTechnologyIconSet();
		cds.loadChartIconSet();
		this.appVersion = packageInfo.version;
	}

	get pipelineData(): PipelinesData {
		return this.appConfig.pipelineData;
	}

	async ngOnInit() {
		if (await this.authCognitoService.isAuthenticated()) {
			this.userDetails = this.userService.getUser();
			this.toastr.info('Cognito session active', 'Welcome back ' + this.userDetails.firstName);
		}
	}

	async doLogout() {
		await this.authCognitoService.signOut();
	}

	async getJWTToken() {
		const currentSession: CognitoUserSession = await this.authCognitoService.getCurrentSession();
		const token = currentSession.getIdToken().getJwtToken();
		await navigator.clipboard.writeText(token);
		this.toastr.info('JWT Token copied to clipboard');
	}
}
