import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../config/app-config';
import { Roles, User } from '../interfaces/user';
import { CognitoUser } from './auth-cognito.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	role: Roles;
	private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	setUserFromCognito(cognitoUser: CognitoUser) {
		// TODO : remove when all the roles are properly set on Cognito
		const role: Roles = this.getRoleFromCognitoGroups(cognitoUser.attributes['custom:groups']);
		const user: User = {
			role: role,
			id: cognitoUser.attributes.sub,
			username: cognitoUser.username,
			email: cognitoUser.attributes.email,
			firstName: cognitoUser.attributes.given_name,
			lastName: cognitoUser.attributes.family_name,
			accountName: cognitoUser.attributes['custom:samaccountname']
		};

		this.user$.next(user);
	}

	getUser() {
		return this.user$.value;
	}

	hasAdminRole() {
		const r = this.getUser()?.role;

		return r === Roles.BUSINESS_ADMIN || r === Roles.ADMIN;
	}

	hasUserRole() {
		return this.getUser()?.role === Roles.USER;
	}

	hasTariffCardAdminRole() {
		return this.getUser()?.role === Roles.TARIFF_CARD_ADMIN;
	}

	private getRoleFromCognitoGroups(groups: string[]): Roles {
		for (const role in Roles) {
			const fullRoleName = AppConfig.instance.groupPrefix + Roles[role];
			if (groups.includes(fullRoleName)) {
				return Roles[role];
			}
		}
	}
}
