import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClrModalModule } from '@clr/angular';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@NgModule({
	declarations: [ConfirmationModalComponent],
	exports: [ConfirmationModalComponent],
	imports: [CommonModule, ClrModalModule]
})
export class ConfirmationModalModule {}
